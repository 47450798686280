<template>
  <v-container fluid>
    <v-card>
      <v-list-item three-line>
        <v-layout align-center>
          <v-img max-height="150" max-width="150" :src="pieChartPic" class="mr-4"></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              THEO DÕI HOẠT ĐỘNG CHẤM CÔNG, ĐĂNG NHẬP TRÊN ĐIỆN THOẠI</v-list-item-title>
            <v-list-item-subtitle>Danh sách các hành vi đăng nhập, chấm công trên điện thoại khác</v-list-item-subtitle>
            <v-switch class="ml-2" @change="capNhatCauHinh" v-model="kichHoat" :label="`${kichHoat ? 'Đã kích hoạt' : 'Đã tắt'}`"></v-switch>
          </v-list-item-content>
        </v-layout>
      </v-list-item>
    </v-card>
    <v-card class="mb-1 mt-3">
      <v-card-title class="pa-6 pb-4">
        <div class="black--text">Danh sách hành vi</div>
        <v-spacer></v-spacer>
        <div style="width: 600px" class="mr-4">
          <v-row>
            <v-col cols="7">
              <v-text-field append-icon="mdi-magnify" v-model="search" label="Tìm kiếm" clearable single-line
                hide-details></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-menu ref="menu" v-model="showDate" :close-on-content-click="false" :return-value.sync="date"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="time" prepend-icon="mdi-calendar" placeholder="Chọn thời gian" readonly
                    v-bind="attrs" v-on="on" clearable></v-text-field>
                </template>

                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showDate = false">
                    Hủy
                  </v-btn>
                  <v-btn text color="primary" @click="changeDate()"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :page.sync="page" :items-per-page="itemsPerPage"
        hide-default-footer :loading="loading" class="elevation-1" loading-text="Đang tải dữ liệu ...">
        <template v-slot:[`item.nguoi_thuc_hien`]="{ item }">
          <v-layout @click="showNguoiThucHien(item)" style="cursor: pointer" align-center>
            <v-avatar color="indigo" size="28">
              <img v-if="item.nguoi_thuc_hien && item.nguoi_thuc_hien.url_image"
                :src="imageEndpoint + item.nguoi_thuc_hien.url_image" alt="ManhLe" />
              <span style="color: white" v-else-if="item && item.nguoi_thuc_hien.name">{{
                  item.nguoi_thuc_hien.name.charAt(0).toUpperCase()
              }}</span>
              <v-icon v-else dark>mdi-account</v-icon>
            </v-avatar>
            <div class="ml-2">
              <div style="font-size: 16px">{{ item.nguoi_thuc_hien && item.nguoi_thuc_hien.name }}</div>
              <div>Mã NV: {{ item.nguoi_thuc_hien && item.nguoi_thuc_hien.nhan_vien ?
                  item.nguoi_thuc_hien.nhan_vien.ma_nhan_vien : ''
              }}</div>
            </div>
          </v-layout>
        </template>
        <template v-slot:[`item.chu_thiet_bi`]="{ item }">
          <v-layout @click="showChuThietBi(item)" style="cursor: pointer"
            v-if="item.chu_thiet_bi && item.chu_thiet_bi.name" align-center>
            <v-avatar color="indigo" size="28">
              <img v-if="item.chu_thiet_bi && item.chu_thiet_bi.url_image"
                :src="imageEndpoint + item.chu_thiet_bi.url_image" alt="ManhLe" />
              <span style="color: white" v-else-if="item.chu_thiet_bi && item.chu_thiet_bi.name">{{
                  item.chu_thiet_bi.name.charAt(0).toUpperCase()
              }}</span>
              <v-icon v-else dark>mdi-account</v-icon>
            </v-avatar>
            <div class="ml-2">
              <div style="font-size: 16px">{{ item.chu_thiet_bi && item.chu_thiet_bi.name }}</div>
              <div>Mã NV: {{ item.chu_thiet_bi && item.chu_thiet_bi.nhan_vien ? item.chu_thiet_bi.nhan_vien.ma_nhan_vien
                  : ''
              }}</div>
            </div>
          </v-layout>
        </template>
        <template v-slot:[`item.thoi_gian`]="{ item }">
          <div>
            {{ new Date(item.created_at).toLocaleDateString('en-GB') }} {{ new
                Date(item.created_at).toLocaleTimeString('en-GB')
            }}
          </div>
        </template>
        <template v-slot:[`item.hanh_dong`]="{ item }">
          <div>
            <span style="font-weight: bold" :style="{color: item.hanh_dong == 'CHO PHÉP' ? 'green' : 'red'}">{{item.hanh_dong}}</span>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <div class="pt-2">
      <v-pagination v-model="page" :length="pageCount" @input="changePage" :total-visible="10">></v-pagination>
    </div>
  </v-container>
</template>
<script>
import pieChartPic from "@/assets/images/piechart.svg";
import { getHanhVi } from "@/api/nhanvien";
import { debounce } from "lodash";
import { cauHinhThietBi, getCauHinhThietBi } from "@/api/thamsohethong";
export default {
  data: () => ({
    pieChartPic,
    tableData: [],
    showDate: false,
    phong_ban_id: null,
    nhom_to_id: null,
    chuc_vu_id: null,
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    search: null,
    loading: false,
    loadingImport: false,
    date: "",
    time: "",
    kichHoat: false,
    headers: [
      { text: "Người thực hiện", value: "nguoi_thuc_hien", sortable: false, width: 250 },
      {
        text: "Hành động",
        align: "start",
        sortable: false,
        value: "loai_hanh_dong",
        width: 150
      },
      {
        text: "Nội dung",
        align: "start",
        sortable: false,
        value: "noi_dung",
      },
      {
        text: "Thời gian",
        align: "start",
        value: "thoi_gian",
      },
      { text: "Chủ thiết bị", value: "chu_thiet_bi", sortable: false, width: 250 },
      {
        text: "Hệ thống",
        align: "start",
        value: "hanh_dong",
      }
    ],
  }),
  mounted() {
    //this.getDateTime();
    this.getData();
    this.getCauHinh()
  },
  watch: {
    search: debounce(async function (val) {
      this.loading = true;
      this.page = 1;
      let data = await getHanhVi({
        perPage: this.itemsPerPage,
        search: val,
      });
      this.loading = false;
      if (data) {
        this.tableData = data.data;
        this.pageCount = data.last_page;
      } else {
        this.tableData = [];
        this.pageCount = 0;
      }
    }, 300),
    time(val) {
      if (!val) {
        this.date = null;
        this.getData()
      }
    },
    // kichHoat() {
    //   this.capNhatCauHinh()
    // }
  },
  methods: {
    async getCauHinh(){
      try {
      let data =  await getCauHinhThietBi();
      this.kichHoat = data &&  data.gia_tri == 1 ? true : false;
      } catch (error) {
        console.log(error)
      }
    },
    async capNhatCauHinh() {
      try {
        await cauHinhThietBi();
        this.$toast.info("Cập nhật thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      } catch (error) {
        console.log(error)
        this.kichHoat = false
      }
    },
    async getData() {
      this.loading = true;
      let data = await getHanhVi({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
        date: this.date
      });
      this.loading = false;
      if (data) {
        this.tableData = data.data;
        this.pageCount = data.last_page;
      } else {
        this.tableData = [];
        this.pageCount = 0;
      }
    },
    taiFIleMau() {
      window.location.href = "/files/danhgia.csv";
    },
    uploadExcel() {
      this.$refs["upload-image"].click();
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    changeDate() {
      this.$refs.menu.save(this.date);
      this.page = 1;
      this.getDateTime();
      this.getData();
    },

    getDateTime() {
      let timenow = this.date ? new Date(this.date) : new Date();
      this.time = timenow.toLocaleDateString('en-GB');
    },
    showMucDong(data) {
      let mucDong = data.find((el) => {
        if (el.ket_thuc) {
          return (
            new Date(el.ket_thuc) > new Date(this.date) &&
            new Date(el.bat_dau) <= new Date(this.date)
          );
        } else {
          return (
            new Date(el.bat_dau) <= new Date(this.date) &&
            new Date() > new Date(this.date)
          );
        }
      });
      if (mucDong) {
        let res = new Intl.NumberFormat({ style: "currency" }).format(
          mucDong.muc_dong
        );
        return res + " VnĐ";
      } else {
        return null;
      }
    },
    showNguoiThucHien(item) {
      let id = item.nguoi_thuc_hien && item.nguoi_thuc_hien.nhan_vien ? item.nguoi_thuc_hien.nhan_vien.id : null
      if (id) {
        this.$router.push("/nhansu/thongtinnhanvien/" + id);
      }
    },
    showChuThietBi(item) {
      let id = item.chu_thiet_bi && item.chu_thiet_bi.nhan_vien ? item.chu_thiet_bi.nhan_vien.id : null
      if (id) {
        this.$router.push("/nhansu/thongtinnhanvien/" + id);
      }
    },
  },
};
</script>