var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-height":"150","max-width":"150","src":_vm.pieChartPic}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1 black--text"},[_vm._v(" THEO DÕI HOẠT ĐỘNG CHẤM CÔNG, ĐĂNG NHẬP TRÊN ĐIỆN THOẠI")]),_c('v-list-item-subtitle',[_vm._v("Danh sách các hành vi đăng nhập, chấm công trên điện thoại khác")]),_c('v-switch',{staticClass:"ml-2",attrs:{"label":("" + (_vm.kichHoat ? 'Đã kích hoạt' : 'Đã tắt'))},on:{"change":_vm.capNhatCauHinh},model:{value:(_vm.kichHoat),callback:function ($$v) {_vm.kichHoat=$$v},expression:"kichHoat"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-1 mt-3"},[_c('v-card-title',{staticClass:"pa-6 pb-4"},[_c('div',{staticClass:"black--text"},[_vm._v("Danh sách hành vi")]),_c('v-spacer'),_c('div',{staticClass:"mr-4",staticStyle:{"width":"600px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","placeholder":"Chọn thời gian","readonly":"","clearable":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showDate = false}}},[_vm._v(" Hủy ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.changeDate()}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.nguoi_thuc_hien",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticStyle:{"cursor":"pointer"},attrs:{"align-center":""},on:{"click":function($event){return _vm.showNguoiThucHien(item)}}},[_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.nguoi_thuc_hien && item.nguoi_thuc_hien.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.nguoi_thuc_hien.url_image,"alt":"ManhLe"}}):(item && item.nguoi_thuc_hien.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.nguoi_thuc_hien.name.charAt(0).toUpperCase()))]):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('div',{staticClass:"ml-2"},[_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.nguoi_thuc_hien && item.nguoi_thuc_hien.name))]),_c('div',[_vm._v("Mã NV: "+_vm._s(item.nguoi_thuc_hien && item.nguoi_thuc_hien.nhan_vien ? item.nguoi_thuc_hien.nhan_vien.ma_nhan_vien : ''))])])],1)]}},{key:"item.chu_thiet_bi",fn:function(ref){
var item = ref.item;
return [(item.chu_thiet_bi && item.chu_thiet_bi.name)?_c('v-layout',{staticStyle:{"cursor":"pointer"},attrs:{"align-center":""},on:{"click":function($event){return _vm.showChuThietBi(item)}}},[_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.chu_thiet_bi && item.chu_thiet_bi.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.chu_thiet_bi.url_image,"alt":"ManhLe"}}):(item.chu_thiet_bi && item.chu_thiet_bi.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.chu_thiet_bi.name.charAt(0).toUpperCase()))]):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('div',{staticClass:"ml-2"},[_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.chu_thiet_bi && item.chu_thiet_bi.name))]),_c('div',[_vm._v("Mã NV: "+_vm._s(item.chu_thiet_bi && item.chu_thiet_bi.nhan_vien ? item.chu_thiet_bi.nhan_vien.ma_nhan_vien : ''))])])],1):_vm._e()]}},{key:"item.thoi_gian",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString('en-GB'))+" "+_vm._s(new Date(item.created_at).toLocaleTimeString('en-GB'))+" ")])]}},{key:"item.hanh_dong",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticStyle:{"font-weight":"bold"},style:({color: item.hanh_dong == 'CHO PHÉP' ? 'green' : 'red'})},[_vm._v(_vm._s(item.hanh_dong))])])]}}],null,true)})],1),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }